exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-2022-03-27-first-entry-index-md": () => import("./../../../src/pages/blog/2022-03-27-first-entry/index.md" /* webpackChunkName: "component---src-pages-blog-2022-03-27-first-entry-index-md" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meeting-newhorizon-js": () => import("./../../../src/pages/meeting-newhorizon.js" /* webpackChunkName: "component---src-pages-meeting-newhorizon-js" */),
  "component---src-pages-newhorizon-finished-js": () => import("./../../../src/pages/newhorizon-finished.js" /* webpackChunkName: "component---src-pages-newhorizon-finished-js" */),
  "component---src-pages-pl-about-us-js": () => import("./../../../src/pages/pl/about-us.js" /* webpackChunkName: "component---src-pages-pl-about-us-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sd-agile-mgm-js": () => import("./../../../src/pages/sd-agile-mgm.js" /* webpackChunkName: "component---src-pages-sd-agile-mgm-js" */),
  "component---src-pages-sd-api-gateway-design-js": () => import("./../../../src/pages/sd-api-gateway-design.js" /* webpackChunkName: "component---src-pages-sd-api-gateway-design-js" */),
  "component---src-pages-sd-architecture-governance-js": () => import("./../../../src/pages/sd-architecture-governance.js" /* webpackChunkName: "component---src-pages-sd-architecture-governance-js" */),
  "component---src-pages-sd-backend-architecture-js": () => import("./../../../src/pages/sd-backend-architecture.js" /* webpackChunkName: "component---src-pages-sd-backend-architecture-js" */),
  "component---src-pages-sd-backend-integration-js": () => import("./../../../src/pages/sd-backend-integration.js" /* webpackChunkName: "component---src-pages-sd-backend-integration-js" */),
  "component---src-pages-sd-devops-design-js": () => import("./../../../src/pages/sd-devops-design.js" /* webpackChunkName: "component---src-pages-sd-devops-design-js" */),
  "component---src-pages-sd-devops-js": () => import("./../../../src/pages/sd-devops.js" /* webpackChunkName: "component---src-pages-sd-devops-js" */),
  "component---src-pages-sd-migration-replication-js": () => import("./../../../src/pages/sd-migration-replication.js" /* webpackChunkName: "component---src-pages-sd-migration-replication-js" */),
  "component---src-pages-sd-mobile-architecture-js": () => import("./../../../src/pages/sd-mobile-architecture.js" /* webpackChunkName: "component---src-pages-sd-mobile-architecture-js" */),
  "component---src-pages-sd-release-mgm-js": () => import("./../../../src/pages/sd-release-mgm.js" /* webpackChunkName: "component---src-pages-sd-release-mgm-js" */),
  "component---src-pages-sd-risk-mgm-js": () => import("./../../../src/pages/sd-risk-mgm.js" /* webpackChunkName: "component---src-pages-sd-risk-mgm-js" */),
  "component---src-pages-sd-security-design-js": () => import("./../../../src/pages/sd-security-design.js" /* webpackChunkName: "component---src-pages-sd-security-design-js" */),
  "component---src-pages-sd-system-monitoring-js": () => import("./../../../src/pages/sd-system-monitoring.js" /* webpackChunkName: "component---src-pages-sd-system-monitoring-js" */),
  "component---src-pages-sd-web-architecture-js": () => import("./../../../src/pages/sd-web-architecture.js" /* webpackChunkName: "component---src-pages-sd-web-architecture-js" */),
  "component---src-pages-services-architecture-js": () => import("./../../../src/pages/services-architecture.js" /* webpackChunkName: "component---src-pages-services-architecture-js" */),
  "component---src-pages-services-processes-js": () => import("./../../../src/pages/services-processes.js" /* webpackChunkName: "component---src-pages-services-processes-js" */),
  "component---src-pages-services-security-js": () => import("./../../../src/pages/services-security.js" /* webpackChunkName: "component---src-pages-services-security-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

